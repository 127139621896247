@import 'Scss/variables';

.side-bar {
  background-color: $white;
  .ant-menu {
    padding: 0 20px;
    .ant-menu-item {
      min-height: 100px;
      border-radius: 5px;
      border: 1px solid $dove-gray-opacity;
      font-size: $font-size-xl;
      color: $ship-gray;
      font-weight: 700;
      background-color: $gallery;
      margin: 0 !important;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &.ant-menu-item-selected {
        background-color: $white;
        svg {
          color: $blue-lagoon;
        }
      }
      &.ant-menu-item-disabled {
        p {
          color: $ship-gray;
        }
      }
      p {
        margin-bottom: 0;
        color: $ship-gray;
        font-size: $font-size-sm;
      }
      &:after {
        border-right: 10px solid $blue-lagoon;
        left: 0;
        right: inherit;
      }
      svg {
        font-size: $font-size-xxl;
        color: $silver;
      }
    }
  }
}
@media screen and (max-width: $xl) {
  .side-bar {
    .ant-menu {
      .ant-menu-item {
        min-height: fit-content;
        margin: 0 0 8px !important;
        padding: 0 !important;
        justify-content: center;
        p {
          display: none;
        }
        &:after {
          border-right: none;
        }
      }
    }
  }
}
