@import 'Scss/variables';

.header {
  background-color: $blue-lagoon;
  width: 100%;
  height: 150px;
  .ant-row {
    width: 100%;
    height: 100%;
    .ant-col {
      height: 100%;
      display: flex;
      align-items: center;
      &:first-child {
        justify-content: center;
      }
      .ant-typography {
        color: #fff;
        font-weight: 400;
        margin-bottom: 0px;
      }
    }
  }
}
