@import 'antd/dist/antd.css';
@import 'Scss/variables';

body {
  margin: 0;
  padding: 0;
  color: $dove-gray-opacity;
  font-family: 'Segoe UI', sans-serif;
  overflow-x: hidden;
}

.ant-layout {
  background-color: #fff;
}

.app-layout {
  margin-top: 24px;
  .ant-layout-content {
    min-height: calc(100vh - 174px);
    padding: 0 20px 20px 0;
  }
}

.spinner-loading {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background-color: rgba(255, 255, 255, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
}

.flex-box {
  display: flex;
  align-items: center;
  &.column {
    flex-direction: column;
  }
  &.center {
    justify-content: center;
  }
  &.evenly {
    justify-content: space-evenly;
  }
  &.between {
    justify-content: space-between;
  }
  &.end {
    justify-content: flex-end;
  }
  &.row-wrap {
    flex-flow: row wrap;
  }
  .flex-item {
    &.large-6 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}

.cancel-btn {
  padding: 1rem 5.25rem;
  font-size: 1.25rem;
  font-weight: 500;
  background-color: $gallery;
  color: $dove-gray-opacity;
  border: 1px solid $shiraz;
  border-radius: 0.2rem;
  box-shadow: $btn-box-shadow;
  cursor: pointer;
  &:hover {
    box-shadow: $btn-box-shadow-hover;
  }
}

.primary-btn.ant-btn {
  border: none;
  color: $white;
  min-height: 50px;
  min-width: 100px;
  border-radius: 5px;
  background: $blue-lagoon;
  font-size: $font-size-sm;
  svg {
    margin-right: 12px;
  }
  &:hover {
    box-shadow: $btn-box-shadow-hover;
  }
  &.reverse {
    color: $ship-gray;
    background-color: transparent;
    border: 1px solid $blue-lagoon;
    svg {
      color: $blue-lagoon;
    }
  }
  &:disabled {
    background-color: $silver;
    border: $silver;
  }
}

.yellow-sea-btn.ant-btn {
  border: 1px solid $crimson;
  border-radius: 0.2rem;
  background-color: $yellow-sea;
  color: $white;
  padding: 0.7rem 1.5rem;
  height: 50px;
  &:hover {
    color: $crimson;
    box-shadow: $btn-box-shadow-hover;
  }
}

.main-content {
  background: $white;
  padding: 1.5rem;
  border-radius: 5px;
  border: 1px solid $dove-gray-opacity;
  font-size: $font-size-sm;
  color: $dove-gray-opacity;
  overflow-x: visible;
  min-height: 100%;

  .ant-form {
    margin: 30px 0;
    &-item {
      flex-flow: column;
      margin: 20px 40px 20px 0;
      &-label {
        text-align: left;
        label {
          color: $ship-gray;
          font-size: $font-size-sm;
        }
      }
      &-control {
        &-input {
          &-content {
            .ant-picker,
            .ant-select {
              width: 300px;
              height: 50px;
              border-radius: 5px;
              border-color: $dove-gray-opacity;

              @media only screen and (max-width: 1600px) {
                width: 280px;
              }
            }
            .ant-picker {
              &-input {
                input {
                  color: $ship-gray;
                  font-size: $font-size-sm;
                  &::placeholder {
                    color: $ship-gray;
                  }
                }
              }
              &-suffix {
                color: $ship-gray;
                font-size: 20px;
              }
              &:hover {
                border-color: $blue-lagoon;
              }

              &.ant-picker-focused {
                box-shadow: 0 0 0 2px #00707e33;
              }
            }
            .ant-select {
              &-selector {
                height: 100%;
                align-items: center;
                border-radius: inherit;
                border-color: inherit;
                height: 100%;
                color: $ship-gray;
                font-size: $font-size-sm;
                .ant-select-selection-search {
                  &-input {
                    height: inherit;
                    color: inherit;
                    font-size: inherit;
                  }
                }
                .ant-select-selection-placeholder {
                  color: $ship-gray;
                }
                &:hover {
                  border-color: $blue-lagoon;
                }
              }
              &-arrow {
                top: 50%;
                color: $ship-gray;
                font-size: 16px;
              }
            }
            .ant-btn {
              min-width: 122px;
              min-height: 50px;
            }
          }
        }
      }
      &:last-child {
        margin-right: 0;
        .ant-form-item-control {
          display: flex;
          .ant-form-item-control-input {
            margin-top: auto;
          }
        }
      }
    }
  }

  .ant-radio-group {
    .ant-radio-wrapper {
      margin-right: 20px;
      .ant-radio {
        &-inner {
          border-color: $blue-lagoon;
          &::after {
            top: 4px;
            left: 4px;
            width: 6px;
            height: 6px;
            background-color: $blue-lagoon;
          }
        }
      }
      span {
        &:last-child {
          color: $ship-gray;
          font-size: $font-size-sm;
        }
      }
    }
  }

  ul.ant-pagination {
    li.ant-pagination-item {
      border: unset;
      color: $ship-gray;
      &:hover {
        a {
          color: $blue-lagoon;
        }
      }
      &.ant-pagination-item-active {
        background-color: $blue-lagoon;
        a {
          color: #fff;
        }
      }
    }
    .ant-pagination-prev,
    .ant-pagination-next {
      button.ant-pagination-item-link {
        border: unset;
        .anticon {
        }
      }
      &:hover {
        button.ant-pagination-item-link {
          color: $blue-lagoon;
        }
      }
    }
  }

  .title-heading {
    margin-top: 0.5rem;
    color: $ship-gray;
    font-size: 21px;
    font-weight: 700;
  }

  .filter-col-3 {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;

    @media only screen and (max-width: $sm) {
      padding: 0;
    }

    .form-item {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
      margin: 1rem 0;
      padding: 0 1.5rem;

      @media only screen and (max-width: $lg) {
        flex: 0 0 50%;
        max-width: 50%;
      }

      @media only screen and (max-width: $sm) {
        flex: 0 0 100%;
        max-width: 100%;
        padding: 0;
      }
    }
  }
  .filter-col-2 {
    display: flex;
    flex-flow: row wrap;
    .form-item {
      flex-basis: 50%;
      max-width: 50%;
    }
  }

  .custom-label {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
    font-weight: bold;
    font-size: $font-size-sm;
    color: $dove-gray-opacity;
  }
  .table-container {
    margin-top: 15px;
  }
}

.text-alert {
  color: $flamingo;
  font-size: 0.8125rem;
  font-style: italic;
}

.highlight-cell {
  color: $crimson;
  cursor: pointer;
}

.app-modal {
  .ant-modal-content {
    color: $dove-gray-opacity;
    .ant-modal-close {
      color: $white;
    }
    .ant-modal-header {
      background-color: $shiraz;
      .ant-modal-title {
        color: $white;
      }
    }
    .ant-modal-body {
      padding: 20px;
    }
    .ant-modal-footer {
      padding: 10px 20px;
      border-top: none;
      text-align: center;
    }
  }
}

.ant-spin-nested-loading {
  & > div:first-child {
    .ant-spin {
      max-height: unset;
      height: 100%;
      .anticon-loading {
      }
      .ant-spin-text {
        padding-top: 50px;
        margin-left: 16px;
      }
    }
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  border-color: $blue-lagoon !important;
  box-shadow: 0 0 0 2px #00707e33 !important;
}
