// --- OLD COLOR ---
$crimson: #dd1032;
$shiraz: #b50826;
$white: #ffffff;
$seashell: #f1f1f1;
$athens-gray: #e1e4e9;
$mercury: #e6e6e6;
$monza: #cf0a2c;
$concrete: #f2f2f2;
$flamingo: #ec5840;
$silver: #cccccc;
$alto: #dddddd;
$alabaster: #fbfbfb;
$yellow-sea: #faa635;
$mine-shaft: #323232;
$silver-chalice: #acacac;
$gallery: #eaeaea;
$black: #000;
$yellow: #f5d76e;
$gray: #f8f8f8;
$silver-regular: #787878;

// --- NEW COLOR ---
$blue-lagoon: #00707E;
$dove-gray-opacity: #70707080;
$gallery: #ededed;
$white: #ffffff;
$ship-gray: #414042;
$silver: #bebebe;
$athens-gray: #f0f2f5;
$blue: #0818f2;
$gamboge: #e8a914;
$jelly-bean: #29849f;
$silver-tree: #6ec2a1;
$ronchi: #eed245;
$mindaro: #e3f587;

// --- BUTTON SHADOW ---
$btn-box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%),
  0 1px 5px 0 rgb(0 0 0 / 12%);
$btn-box-shadow-hover: 0 8px 10px 1px rgb(0 0 0 / 14%),
  0 3px 14px 2px rgb(0 0 0 / 12%), 0 5px 5px -3px rgb(0 0 0 / 20%);

// --- FONT SIZE ---
$font-size-sm: 15px;
$font-size-md: 17px;
$font-size-lg: 19px;
$font-size-xl: 21px;
$font-size-xxl: 25px;

// --- SCREEN RESOLUTION ---
$xs: 480px;
$sm: 576px;
$md: 768px;
$lg: 992px;
$lgm: 1024px;
$xl: 1200px;
$xxl: 1600px;
