@import 'Scss/variables';

.ant-layout-header.parent-header {
  background: $shiraz;
  display: flex;
  align-items: center;
  height: 116px;
  padding: 0 5%;
  .parent-logo {
    height: 100px;
  }
  .top-bar-left {
    img {
      cursor: pointer;
    }
  }
  .top-bar-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;

    .menu-bar {
      .menu-bar-link {
        color: $white;
        padding: 8px 10px;

        &:not(:last-child) {
          margin-right: 20px;

          @media only screen and (max-width: $xl) {
            margin-right: 14px;
          }
        }

        &.active {
          border-bottom: 2px solid $yellow-sea;
        }
      }
    }

    .user-profile {
      display: flex;
      align-items: center;
      margin-left: 16px;
      background-color: $shiraz;
      border-bottom: none;
      color: $white;
      .ant-menu-submenu {
        border: 1px solid $crimson;
        border-radius: 4px;
        &.ant-menu-submenu-open {
          color: $white;
        }
        &.ant-menu-submenu-selected {
          color: $white;
        }
        &:hover {
          background-color: $white;
          color: $shiraz;
        }
        .ant-menu-submenu-title {
          height: 40px;
          line-height: 25px;
          // border: 1px solid #cf0a2c;
          border-radius: 0.2rem;
          padding: 0.5rem 2.5rem 0.5rem 1rem;
          &:hover {
            color: $shiraz;
            .ant-menu-submenu-arrow {
              color: $shiraz;
            }
          }

          .ant-menu-submenu-arrow {
            display: block;
            transform: rotate(90deg);
            color: $white;
          }
        }
      }
    }
  }
}

.ant-layout-footer.parent-footer {
  background-color: #353535;
  font-size: 0.7rem;
  padding: 0;
  margin-top: auto;
  .campus-info-list {
    padding: 2rem 4rem;
    display: flex;
    flex-flow: row wrap;
    max-width: 100%;

    .column {
      flex: 0 0 25%;
      max-width: 25%;
      .campus-title {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 1rem;
        color: $white;
      }
      .campus-text {
        color: #dadada;
        line-height: 1.375rem;
      }
    }
  }
  .bottom-bar {
    display: table;
    width: 100%;
    background-color: #000000;
    padding: 15px 4rem;
    .copyright {
      float: right;
      display: table-cell;
      vertical-align: middle;
      span {
        color: $white;
      }
    }
  }
}
